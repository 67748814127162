import { Link } from "react-router-dom";
import { Layout } from "../components";
import * as Yup from "yup";
import { Form, Input, Button, Typography, message } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { useParams } from "react-router-dom";
import { confirmPhoneAndEmail } from "../api/confirmPhoneAndEmail";
import { useState } from "react";
const VerificationSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]{6}?$/im, "Nieprawidłowy kod!")
    .required("Pole obowiązkowe"),
});

const initialValues: { code: string } = {
  code: "",
};

interface ConfirmMailProps {}
export const ConfirmMail: React.FC<ConfirmMailProps> = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const params = useParams();

  const handleSubmit = async (values: { code: string }, actions: any) => {
    try {
      if (!params.uid) return;
      const res = await confirmPhoneAndEmail({ linkToken: params.uid, smsToken: values.code });
      if (res === "ERROR") {
        actions.setFieldError("code", "Weryfikacja nie udana!");
        message.error("Link lub kod SMS niepoprawny... Weryfikacja nie udana!");
      } else if (res === "OK") {
        setIsSuccess(true);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Layout>
      {isSuccess ? (
        <>
          <Typography.Title type="success" style={{ textAlign: "left" }}>
            Weryfikacja udana
          </Typography.Title>
          <Typography.Text>
            Po pozytywnej akceptacji konta przez administracje firmy Majster, na podany adres email otrzymasz wiadomość
            z tymczasowym hasłem.
          </Typography.Text>
        </>
      ) : (
        <>
          <Typography.Title level={1} style={{ fontSize: "30px", marginBottom: "24px", textAlign: "left" }}>
            Weryfikacja adresu email i nr. telefonu.
          </Typography.Title>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginBottom: "12px" }}>
            <Typography.Text>
              Aby zweryfikować numer telefonu oraz adres email, wprowadź kod weryfikacyjny wysłany na numer wprowadzony
              podczas rejestracji.
            </Typography.Text>
          </div>
          <Formik validationSchema={VerificationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange, errors, touched, isSubmitting }) => (
              <FormikForm style={{ display: "flex" }}>
                <Form.Item style={{ maxWidth: "150px" }}>
                  <Input placeholder="Wprowadź kod z SMS" name="code" value={values.code} onChange={handleChange} />
                  {errors.code && touched.code ? (
                    <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                      {errors.code}
                    </Typography.Text>
                  ) : null}
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button" loading={isSubmitting}>
                    Wyślij
                  </Button>
                </Form.Item>
              </FormikForm>
            )}
          </Formik>
        </>
      )}
      <div style={{ marginTop: "12px", marginBottom: "12px" }}>
        {/* <Button disabled={counter < COUNTER_VALUE} onClick={handleSendConfirmEmail}>
          Wyślij ponownie {counter < COUNTER_VALUE ? `(${counter})` : ""}
        </Button> */}
        <Link to="/">Strona logowania</Link>
      </div>
    </Layout>
  );
};
