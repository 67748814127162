import api from "../../../lib/withAxios";

interface ChangePasswordDAO {
  oldPassword: string;
  newPassword: string;
}

export const changePassword = async (data: ChangePasswordDAO) => {
  return api.post(`/pracownik/zmianaHasla`, data).then((res) => res.data);
};
