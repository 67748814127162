import api from "../../../lib/withAxios";

interface ResetPasswordDAO {
  emailToken: string;
  smsToken: string;
  newPassword: string;
}

export const resetPassword = async (data: ResetPasswordDAO) => {
  return api.post(`/public/pracownik/ustawNoweHaslo`, data).then((res) => res.data);
};
