import * as Yup from "yup";
import { Form, Input, Typography, Card } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { SubHeader } from "../SubHeader";
import { GeneralInfo, UserCred } from "../../types";

interface GeneralInformationProps {
  user: UserCred;
}
const GeneralInfoSchema = Yup.object().shape({
  imie: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
  nazwisko: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
  telefon: Yup.string()
    .matches(/^[0-9]{9}?$/im, "Nieprawidłowy numer!")
    .required("Pole obowiązkowe"),
});

const GeneralInformation: React.FC<GeneralInformationProps> = ({ user }) => {
  const initialValues: GeneralInfo = {
    imie: user.imie,
    nazwisko: user.nazwisko,
    telefon: user.telefon?.substring(3),
  };
  const handleSubmit = async (values: GeneralInfo, actions: any) => {
    try {
      // await updateUserCred({ ...values, telefon: `+48${values.telefon}` });
      // getDetails();
      // message.success("Aktualizacja udana!");
    } catch (err: any) {
      console.log(err.response);
    }
  };

  return (
    <Card bordered={false}>
      <Formik validationSchema={GeneralInfoSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleChange, errors, touched, isValid }) => (
          <FormikForm className="user-edit-form">
            <SubHeader title="Informacje ogólne" />
            <div className="user-edit-form-side-wrapper">
              <Form.Item label="Imię">
                <Input placeholder="Imię" name="imie" value={values.imie} onChange={handleChange} disabled />
                {errors.imie && touched.imie ? (
                  <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                    {errors.imie}
                  </Typography.Text>
                ) : null}
              </Form.Item>

              <Form.Item label="Nazwisko">
                <Input
                  name="nazwisko"
                  placeholder="Nazwisko"
                  value={values.nazwisko}
                  onChange={handleChange}
                  disabled
                />
                {errors.nazwisko && touched.nazwisko ? (
                  <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                    {errors.nazwisko}
                  </Typography.Text>
                ) : null}
              </Form.Item>
            </div>
            <Form.Item label="Numer telefonu" className="user-edit-form-half-width-input">
              <Input
                disabled
                addonBefore="+48"
                name="telefon"
                placeholder="Nr telefonu"
                value={values.telefon}
                onChange={handleChange}
              />
              {errors.telefon && touched.telefon ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.telefon}
                </Typography.Text>
              ) : null}
            </Form.Item>
          </FormikForm>
        )}
      </Formik>
    </Card>
  );
};

export default GeneralInformation;
