import { Link } from "react-router-dom";
import { Layout } from "../components";
import * as Yup from "yup";
import { Form, Input, Button, Typography, Badge, Tooltip } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { useParams } from "react-router-dom";
import { resetPassword } from "../api/resetPassword";
import { useState } from "react";
import { QuestionCircleFilled } from "@ant-design/icons";

const VerificationSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]{6}?$/im, "Nieprawidłowy kod!")
    .required("Pole obowiązkowe"),
  newPassword: Yup.string()
    .max(20, "Maximum 20 znaków!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      "Hasło musi zawierać minimum 8 znaków, conajmniej jedną dużą, małą literę, jedną cyfrę oraz jeden znak specjalny (!@#$%^&*()_+)"
    )
    .required("Pole obowiązkowe!"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Hasła nie są takie same!")
    .required("Pole obowiązkowe!"),
});

const initialValues: { code: string; newPassword: string; repeatPassword: string } = {
  code: "",
  newPassword: "",
  repeatPassword: "",
};

interface ResetPasswordProps {}
export const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const params = useParams();

  const handleSubmit = async (values: { code: string; newPassword: string; repeatPassword: string }, actions: any) => {
    try {
      if (!params.uid) return;
      await resetPassword({
        emailToken: params.uid,
        smsToken: values.code,
        newPassword: values.newPassword,
      });
      setIsSuccess(true);
    } catch (err: any) {
      console.log("err");
    }
  };

  return (
    <Layout>
      {isSuccess ? (
        <>
          <Typography.Title type="success">Zmieniono hasło</Typography.Title>
          <Typography.Text>Możesz przejść na stronę logowania i zalogować się</Typography.Text>
        </>
      ) : (
        <>
          <Typography.Title level={1} style={{ fontSize: "30px", marginBottom: "24px" }}>
            Wprowadź nowe hasło
          </Typography.Title>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginBottom: "12px" }}>
            <Typography.Text>
              Aby zresetować hasło, wprowadź kod weryfikacyjny wysłany na twój numer telefonu oraz nowe haslo
            </Typography.Text>
          </div>
          <Formik validationSchema={VerificationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange, errors, touched, isSubmitting }) => (
              <FormikForm style={{ display: "flex", flexDirection: "column" }}>
                <Form.Item
                  label={
                    <Badge
                      offset={[15, 0]}
                      count={
                        <Tooltip title="Hasło musi zawierać minimum 8 znaków, conajmniej jedną dużą, małą literę, jedną cyfrę oraz jeden znak specjalny (!@#$%^&*()_+)">
                          <QuestionCircleFilled />
                        </Tooltip>
                      }
                    >
                      <span>Nowe hasło</span>
                    </Badge>
                  }
                >
                  <Input.Password
                    type="password"
                    name="newPassword"
                    placeholder="Podaj nowe hasło"
                    value={values.newPassword}
                    onChange={handleChange}
                    autoComplete="new-password"
                  />
                  {errors.newPassword && touched.newPassword ? (
                    <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                      {errors.newPassword}
                    </Typography.Text>
                  ) : null}
                </Form.Item>
                <Form.Item label="Powtórz hasło">
                  <Input.Password
                    type="password"
                    name="repeatPassword"
                    placeholder="Powtórz hasło"
                    value={values.repeatPassword}
                    onChange={handleChange}
                    autoComplete="new-password"
                  />
                  {errors.repeatPassword && touched.repeatPassword ? (
                    <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                      {errors.repeatPassword}
                    </Typography.Text>
                  ) : null}
                </Form.Item>
                <Form.Item label="Kod SMS">
                  <Input placeholder="Wprowadź kod z SMS" name="code" value={values.code} onChange={handleChange} />
                  {errors.code && touched.code ? (
                    <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                      {errors.code}
                    </Typography.Text>
                  ) : null}
                </Form.Item>
                <Form.Item style={{ maxWidth: "100px" }}>
                  <Button type="primary" htmlType="submit" className="login-form-button" loading={isSubmitting}>
                    Wyślij
                  </Button>
                </Form.Item>
              </FormikForm>
            )}
          </Formik>
        </>
      )}
      <div style={{ marginTop: "12px", marginBottom: "12px" }}>
        <Link to="/">Strona logowania</Link>
      </div>
    </Layout>
  );
};
