import "./style.less";
import { Layout, Typography } from "antd";
import AppVersion from "../AppVersion";
interface AppFooterProps {}

const AppFooter: React.FC<AppFooterProps> = () => {
  return (
    <Layout.Footer className="main-footer">
      <div className="main-footer-wrapper">
        <Typography.Text type="secondary">Copyright © TOP S.A. 2022</Typography.Text>
        <AppVersion />
      </div>
    </Layout.Footer>
  );
};

export default AppFooter;
