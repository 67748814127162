import { Typography } from "antd";
import { Layout, LoginForm } from "../components";

interface LoginProps {}
export const Login: React.FC<LoginProps> = () => {
  return (
    <Layout>
      <Typography.Title level={1} style={{ fontSize: "30px", marginBottom: "24px" }}>
        Zaloguj się na swoje konto
      </Typography.Title>
      <LoginForm />
    </Layout>
  );
};
