import "moment/locale/pl";
import React from "react";
import plPL from "antd/lib/locale/pl_PL";
import moment from "moment";
import Spinner from "../components/Spinner";
import { ErrorBoundary } from "react-error-boundary";
import AuthProvider from "./auth";
import { AppRoutes } from "../routes";
import { ConfigProvider } from "antd";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../lib/react-query";
import { WithAdminAxios } from "../lib/withAdminAxios";
import { WithAxios } from "../lib/withAxios";
moment.locale("pl");

const ErrorFallback = ({ ...props }: any) => {
  const is404 = props.error && props.error.response && props.error.response.status === 404;

  return (
    <div>
      <h2>{is404 ? "Nie ma takiej strony" : "Ooops, something went wrong :("} </h2>
      {is404 ? (
        <a href="/"> Wróć na stronę główną!</a>
      ) : (
        <button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
          Refresh
        </button>
      )}
    </div>
  );
};

export const AppProvider = () => {
  return (
    <React.Suspense
      fallback={
        <div>
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={(props) => <ErrorFallback {...props} />}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider locale={plPL}>
            <AuthProvider>
              <WithAdminAxios>
                <WithAxios>
                  <AppRoutes />
                </WithAxios>
              </WithAdminAxios>
            </AuthProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
