import * as Yup from "yup";
import { Form, Input, Typography, Card } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { SubHeader } from "../SubHeader";
import { SpecificInfo, UserCred } from "../../types";

interface SpecificInformationProps {
  user: UserCred;
}
const SpecificInformationSchema = Yup.object().shape({
  name: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
  surname: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
});

const SpecificInformation: React.FC<SpecificInformationProps> = ({ user }) => {
  const initialValues: SpecificInfo = {
    email: user.email,
    password: "12345678",
    pesel: user.pesel,
  };

  const handleSubmit = async (values: SpecificInfo, actions: any) => {};

  return (
    <Card bordered={false}>
      <Formik validationSchema={SpecificInformationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleChange, errors, touched }) => (
          <FormikForm className="user-edit-form">
            <SubHeader title="Informacje szczegółowe" />

            <Form.Item label="Email">
              <Input placeholder="Email" name="email" value={values.email} onChange={handleChange} disabled />
              {errors.email && touched.email ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.email}
                </Typography.Text>
              ) : null}
            </Form.Item>
            <Form.Item label="Pesel" className="user-edit-form-half-width-input">
              <Input name="pesel" placeholder="pesel" value={values.pesel} disabled />
            </Form.Item>
          </FormikForm>
        )}
      </Formik>
    </Card>
  );
};

export default SpecificInformation;
