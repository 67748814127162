import api from "../../../lib/withAxios";

interface RegisterFormDAO {
  imie: string;
  nazwisko: string;
  email: string;
  pesel: string;
  telefon: string;
}

export const registerWithEmail = async (data: RegisterFormDAO) => {
  return api.post("/public/pracownik/zgloszenie", data).then((res) => res.data);
};
