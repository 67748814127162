import { useEffect } from "react";
import { useAuth } from "../providers/auth";
import axios from "axios";
import { User } from "../types";
import { message } from "antd";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const WithAxios = ({ children }: any) => {
  const { handleUnauthorized } = useAuth();

  useEffect(() => {
    api.interceptors.request.use(
      async (config) => {
        const loggedUser = sessionStorage.getItem("RCMB_USER_AUTH") || null;
        try {
          const user: User | null = loggedUser ? JSON.parse(loggedUser) : null;
          if (!user) throw new Error("Unauthorized");
          config.headers.Authorization = `Basic ${user.token}`;
        } catch (err) {}
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }, []);
  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response && error.response.status === 401) {
          handleUnauthorized();
        }
        if (error.response && error.response.data && error.response.data.message) {
          message.error(error.response.data.message);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, []);

  return children;
};

export { WithAxios };
export default api;
