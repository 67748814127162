import { useState } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Typography, message, Checkbox } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { registerWithEmail } from "../api/register";
import { Link, useNavigate } from "react-router-dom";
import { checkIfPeselValid } from "../../../utils/peselValidation";

const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Niepoprawny email").required("Pole obowiązkowe"),
  imie: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
  nazwisko: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
  pesel: Yup.string().length(11, "Pesel musi zawierać 11 znaków!").required("Pole obowiązkowe"),
  regulamin1: Yup.boolean().oneOf([true], "Pole obowiązkowe"),
  regulamin2: Yup.boolean().oneOf([true], "Pole obowiązkowe"),
  regulamin3: Yup.boolean().oneOf([true], "Pole obowiązkowe"),
  telefon: Yup.string()
    .matches(/^[0-9]{9}?$/im, "Nieprawidłowy numer!")
    .required("Pole obowiązkowe"),
});

interface RegisterFormProps {}

interface RegisterFormValues {
  imie: string;
  nazwisko: string;
  email: string;
  pesel: string;
  telefon: string;
  regulamin1: boolean;
  regulamin2: boolean;
  regulamin3: boolean;
}

const initialValues: RegisterFormValues = {
  imie: "",
  nazwisko: "",
  email: "",
  pesel: "",
  telefon: "",
  regulamin1: false,
  regulamin2: false,
  regulamin3: false,
};

export const RegisterForm: React.FC<RegisterFormProps> = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: RegisterFormValues, actions: any) => {
    if (!checkIfPeselValid(values.pesel)) return actions.setFieldError("pesel", "Nieprawidłowy Pesel");

    setLoading(true);
    try {
      await registerWithEmail({ ...values, telefon: `+48${values.telefon}` });
      message.success("Rejestracja pomyślna!");

      setLoading(false);
      navigate("/rejestracja/success", { state: { fromRegister: true, userName: values.imie } });
    } catch (err: any) {
      setLoading(false);

      if (!err.response) return message.error("Coś poszło nie tak!");
      else if (err.response.status === 503) return actions.setFieldError("telefon", "Serwer chwilowo niedostępny");

      if (err.response.data?.errors) {
        err.response.data.errors.forEach(
          (err: { field: "imie" | "nazwisko" | "email" | "pesel" | "telefon"; defaultMessage: string }) => {
            if (values[err.field] === undefined) return;
            actions.setFieldError(err.field, err.defaultMessage);
          }
        );
      }
    }
  };

  return (
    <Formik validationSchema={SignInSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, errors, touched, setFieldValue }) => (
        <FormikForm className="form-wrapper">
          <div className="form-wrapper-half">
            <Form.Item label="Imię" required>
              <Input placeholder="Imię" name="imie" value={values.imie} onChange={handleChange} />
              {errors.imie && touched.imie ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.imie}
                </Typography.Text>
              ) : null}
            </Form.Item>

            <Form.Item label="Nazwisko" required>
              <Input placeholder="Nazwisko" name="nazwisko" value={values.nazwisko} onChange={handleChange} />
              {errors.nazwisko && touched.nazwisko ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.nazwisko}
                </Typography.Text>
              ) : null}
            </Form.Item>
          </div>

          <Form.Item label="Pesel" required>
            <Input placeholder="Pesel" name="pesel" maxLength={11} value={values.pesel} onChange={handleChange} />
            {errors.pesel && touched.pesel ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.pesel}
              </Typography.Text>
            ) : null}
          </Form.Item>

          <Form.Item label="Email" required>
            <Input type="email" placeholder="Email" name="email" value={values.email} onChange={handleChange} />
            {errors.email && touched.email ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.email}
              </Typography.Text>
            ) : null}
          </Form.Item>

          <Form.Item label="Numer telefonu" required>
            <Input
              addonBefore="+48"
              type="tel"
              placeholder="Numer telefonu"
              name="telefon"
              value={values.telefon}
              onChange={handleChange}
            />
            {errors.telefon && touched.telefon ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.telefon}
              </Typography.Text>
            ) : null}
          </Form.Item>

          <Form.Item className="form-wrapper-check" required name="regulamin1">
            <Checkbox
              checked={values.regulamin1}
              onChange={(e) => {
                setFieldValue("regulamin1", e.target.checked);
              }}
            >
              Oświadczam, że zapoznałem się z Zasadami działania platformy, rozumiem je i akceptuję.{" "}
              <a href="/docs/Regulamin_epracownik.pdf" download="Regulamin_ePracownik">
                Regulamin ePracownik
              </a>
            </Checkbox>
            {errors.regulamin1 && touched.regulamin1 ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.regulamin1}
              </Typography.Text>
            ) : null}
          </Form.Item>
          <Form.Item className="form-wrapper-check" required name="regulamin2">
            <Checkbox
              checked={values.regulamin2}
              onChange={(e) => {
                setFieldValue("regulamin2", e.target.checked);
              }}
            >
              Oświadczam, że dokonuję osobistej rejestracji na platformie i potwierdzam prawdziwość wprowadzonych przeze
              mnie danych.
            </Checkbox>{" "}
            {errors.regulamin2 && touched.regulamin2 ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.regulamin2}
              </Typography.Text>
            ) : null}
          </Form.Item>
          <Form.Item className="form-wrapper-check" required name="regulamin3">
            <Checkbox
              checked={values.regulamin3}
              onChange={(e) => {
                setFieldValue("regulamin3", e.target.checked);
              }}
            >
              Oświadczam, że mam świadomość, iż w każdym momencie mogę cofnąć zgodę na otrzymywanie dokumentów w formie
              elektronicznej poprzez osobisty kontakt z działem kadr pracodawcy.
            </Checkbox>
            {errors.regulamin3 && touched.regulamin3 ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.regulamin3}
              </Typography.Text>
            ) : null}
          </Form.Item>
          <Form.Item className="form-wrapper-buttons">
            <Button type="primary" htmlType="submit" className="login-form-button" disabled={loading} loading={loading}>
              Zarejestruj się
            </Button>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="/">Masz już konto?</Link>
              <Link to="/rejestracja/zapomnialem-hasla">Zapomnialem hasła</Link>
            </div>
          </Form.Item>
        </FormikForm>
      )}
    </Formik>
  );
};
