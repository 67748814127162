import { Typography } from "antd";

interface HeaderProps {
  title: string;
  level?: 1 | 2 | 3 | 4 | 5;
  style?: any;
}

const Header: React.FC<HeaderProps> = ({ title, level = 1, style }) => {
  return (
    <Typography.Title style={style || {}} level={level}>
      {title}
    </Typography.Title>
  );
};

export default Header;
