import { Link } from "react-router-dom";
import { Layout } from "../components";
import * as Yup from "yup";
import { Form, Input, Button, Typography } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { forgotPassword } from "../api/forgotPassword";
import { useState } from "react";
const ForgotPasswordSchema = Yup.object().shape({
  username: Yup.string().email("Niepoprawny email").required("Pole obowiązkowe"),
});

const initialValues: { username: string } = {
  username: "",
};

interface ForgotPasswordProps {}
export const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (values: { username: string }, actions: any) => {
    try {
      await forgotPassword(values);

      setIsSuccess(true);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <Typography.Title level={1} style={{ fontSize: "30px", marginBottom: "24px", textAlign: "left" }}>
        Zapomniałem hasła
      </Typography.Title>

      {isSuccess ? (
        <Typography.Text>Na twój adres e-mail została wysłana instrukcja zmiany hasła.</Typography.Text>
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginBottom: "16px" }}>
            <Typography.Text>
              Jeśli nie pamiętasz hasła, wpisz adres e-mail, z którego korzystasz w Epracowniku. Na podany adres wyślemy
              instrukcję zmiany hasła.
            </Typography.Text>
          </div>
          <Formik validationSchema={ForgotPasswordSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange, errors, touched, isSubmitting }) => (
              <FormikForm style={{ display: "flex" }}>
                <Form.Item style={{ maxWidth: "250px", width: "100%" }}>
                  <Input placeholder="E-mail" name="username" value={values.username} onChange={handleChange} />
                  {errors.username && touched.username ? (
                    <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                      {errors.username}
                    </Typography.Text>
                  ) : null}
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button" loading={isSubmitting}>
                    Resetuj
                  </Button>
                </Form.Item>
              </FormikForm>
            )}
          </Formik>
        </>
      )}

      <div style={{ marginTop: "12px", marginBottom: "12px" }}>
        <Link to="/">Strona logowania</Link>
      </div>
    </Layout>
  );
};
