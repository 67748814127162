import { Navigate } from "react-router";
import {
  Login,
  Register,
  RegisterSuccess,
  ConfirmMail,
  LoginAdmin,
  ForgotPassword,
  ResetPassword,
} from "../features/auth";

export const publicRoutes = [
  { path: "/", element: <Login /> },
  { path: "/rejestracja", element: <Register /> },
  { path: "/rejestracja/success", element: <RegisterSuccess /> },
  { path: "/rejestracja/zapomnialem-hasla", element: <ForgotPassword /> },
  { path: "/mail/potwierdz/:uid", element: <ConfirmMail /> },
  { path: "/ustaw-nowe-haslo/:uid", element: <ResetPassword /> },
  {
    path: "/admin",
    element: <LoginAdmin />,
  },
  {
    path: "/templates/*",
    element: <Navigate to="/" />,
  },
];
