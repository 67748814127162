import api from "../../../lib/withAxios";

interface ConfirmDAO {
  linkToken: string;
  smsToken: string;
}

export const confirmPhoneAndEmail = async (data: ConfirmDAO) => {
  return api
    .post(
      `/public/pracownik/zgloszenie/potwierdzMailAndTelefon?linkToken=${data.linkToken}&smsToken=${data.smsToken}`,
      data
    )
    .then((res) => res.data);
};
