import Header from "../../../components/Header";
import { Button, message } from "antd";
import { ConsentsCard } from "../components";
import { useAuth } from "../../../providers/auth";
import { Layout } from "../../auth";
import { useState } from "react";
import { checkIfUserAlreadyAccepted } from "../../../utils/consents";
interface ConsentsFullPageProps {}

export const ConsentsFullPage: React.FC<ConsentsFullPageProps> = () => {
  const { setIsFirstEntry, consents } = useAuth();
  const [loading, setLoading] = useState(false);

  const checkAndSave = async () => {
    setLoading(true);
    try {
      const isAlreadyOneAccepted = checkIfUserAlreadyAccepted(consents);
      if (!isAlreadyOneAccepted) {
        message.warning("Zaakceptuj lub odrzuć zgody aby przejść dalej");
      } else {
        setIsFirstEntry(false);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error("Wystąpił błąd podczas pobierania zgód!");
      setLoading(false);
    }
  };
  return (
    <Layout contentFullWidth>
      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div style={{ padding: "24px", marginTop: "24px" }}>
          <Header title="Zgody na dokumenty elektroniczne" level={2} />
        </div>
        <div style={{ height: "45vh", overflowY: "auto" }}>
          {consents?.map((consent) => (
            <ConsentsCard data={consent} key={consent.id} />
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "24px 24px 0 24px" }}>
          <Button type="primary" onClick={checkAndSave} disabled={loading}>
            Przejdź dalej
          </Button>
        </div>
      </div>
    </Layout>
  );
};
