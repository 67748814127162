import "./style.less";
import { Card, Typography } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../providers/auth";
interface UserDetailsCardProps {}

export const UserDetailsCard: React.FC<UserDetailsCardProps> = () => {
  const { currentUser } = useAuth();

  return (
    <Card title="Twoje dane" bordered={false} className="user-details">
      <ul className="user-details-list">
        <li>
          <Typography.Text type="secondary">Imię i nazwisko:</Typography.Text>
          <Typography.Text strong>{currentUser?.kto}</Typography.Text>
        </li>
        <li>
          <Typography.Text type="secondary">Pesel:</Typography.Text>
          <Typography.Text strong>{currentUser?.pesel}</Typography.Text>
        </li>
        <li>
          <Typography.Text type="secondary">Adres email:</Typography.Text>
          <Typography.Text strong>{currentUser?.email}</Typography.Text>
        </li>
        <li>
          <Typography.Text type="secondary">Numer telefonu:</Typography.Text>
          <Typography.Text strong>{currentUser?.telefon}</Typography.Text>
        </li>
      </ul>
      <Link to="/konto" className="user-details-link">
        Dane osobowe
      </Link>
    </Card>
  );
};
