import { Consent } from "../features/staff";

export const checkIfUserAlreadyAccepted = (consents: Consent[] | null) => {
  if (!consents) return false;
  let alreadyAccepted = false;
  consents.forEach((consent) => {
    if (consent.zgoda === 1 || consent.zgoda === -1) return (alreadyAccepted = true);
  });
  return alreadyAccepted;
};
