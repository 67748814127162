import "./style.less";
import { Spin } from "antd";
interface SpinnerProps {}

const Spinner: React.FC<SpinnerProps> = () => {
  return (
    <div className="spinner">
      <Spin size="small" />
    </div>
  );
};

export default Spinner;
