import { Suspense } from "react";
import { Navigate } from "react-router";
import { Outlet } from "react-router-dom";
import { AdminLayout } from "../features/admin";
import { lazyImport } from "../utils/lazyImport";
import MainLayout from "../components/Layout";
import Spinner from "../components/Spinner";

const { UserDetails } = lazyImport(() => import("../features/staff"), "UserDetails");
const { Consents } = lazyImport(() => import("../features/staff"), "Consents");
const { Dashboard } = lazyImport(() => import("../features/common"), "Dashboard");
const { DocumentsRoutes } = lazyImport(() => import("../features/download"), "DocumentsRoutes");
const { AdminRoutes } = lazyImport(() => import("../features/admin"), "AdminRoutes");
const App = () => {
  return (
    <MainLayout>
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "/dokumenty/*", element: <DocumentsRoutes /> },
      { path: "/konto", element: <UserDetails /> },
      { path: "/kadry/zgody", element: <Consents /> },
      { path: "/rejestracja", element: <Navigate to="/" /> },
    ],
  },
];

const Admin = () => {
  return (
    <AdminLayout>
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </AdminLayout>
  );
};
export const protectedAdminRoutes = [
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "/admin/panel/*",
        element: <AdminRoutes />,
      },
      { path: "/admin", element: <Navigate to="/admin/panel" /> },
    ],
  },
];
