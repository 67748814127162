import React, { useEffect, useMemo, useState } from "react";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useAuth } from "../../providers/auth";
import { Link, useLocation } from "react-router-dom";
import { HomeOutlined, TeamOutlined, FileTextOutlined, LogoutOutlined, FileDoneOutlined } from "@ant-design/icons";

interface MainNavProps {
  handleCollapse: () => void;
}

const MainNav: React.FC<MainNavProps> = ({ handleCollapse }) => {
  const location = useLocation();
  const { handleLogout, consents } = useAuth();
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  const navData = useMemo(() => {
    if (!consents)
      return [
        {
          id: 1,
          key: "1",
          name: "Panel główny",
          path: "/",
          icon: <HomeOutlined />,
          disabled: false,
          subMenu: false,
        },
        {
          id: 8,
          key: "8",
          name: "Konto",
          path: "/konto",
          icon: <TeamOutlined />,
          subMenu: false,
          disabled: false,
        },
        {
          id: 9,
          key: "9",
          name: "Zgody",
          path: "/kadry/zgody",
          icon: <FileDoneOutlined />,
          disabled: false,
          subMenu: false,
        },

        {
          id: 4,
          key: "4",
          name: "Do pobrania",
          path: "/dokumenty",
          icon: <FileTextOutlined />,
          disabled: false,
          subMenu: [],
        },
      ];
    return [
      {
        id: 1,
        key: "1",
        name: "Panel główny",
        path: "/",
        icon: <HomeOutlined />,
        disabled: false,
        subMenu: false,
      },
      {
        id: 8,
        key: "8",
        name: "Konto",
        path: "/konto",
        icon: <TeamOutlined />,
        subMenu: false,
        disabled: false,
      },
      {
        id: 9,
        key: "9",
        name: "Zgody",
        path: "/kadry/zgody",
        icon: <FileDoneOutlined />,
        disabled: false,
        subMenu: false,
      },

      {
        id: 4,
        key: "4",
        name: "Do pobrania",
        path: "/dokumenty",
        icon: <FileTextOutlined />,
        disabled: false,
        subMenu: [
          ...consents.map((consent) => ({
            id: 7,
            key: consent.id,
            name: consent.symbol,
            path: `/dokumenty/${consent.symbol}`,
            icon: false,
            subMenu: false,
            disabled: false,
            // zmiana 23.02.2024 ML na polecenie Kuby
            // disabled: consent.zgoda === 1 ? false : true,
          })),
        ],
      },
    ];
  }, [consents]);

  const handleSetSelected = (path: string, disabled: boolean) => {
    if (disabled) return;
    setSelectedKey(path);
  };
  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);
  return (
    <Menu mode="inline" selectedKeys={[selectedKey]} className="main-manu" theme="light">
      {navData.map((menuEl) => {
        if (!menuEl.subMenu) {
          return (
            <Menu.Item key={menuEl.path} icon={menuEl.icon} disabled={menuEl.disabled}>
              <Link to={menuEl.path} onClick={() => handleSetSelected(menuEl.path, menuEl.disabled)}>
                {menuEl.name}
              </Link>
            </Menu.Item>
          );
        } else if (Array.isArray(menuEl.subMenu)) {
          return (
            <SubMenu key={menuEl.key} icon={menuEl.icon} title={menuEl.name} disabled={menuEl.disabled}>
              {menuEl.subMenu.map((subEl) => (
                <Menu.Item key={subEl.path} disabled={subEl.disabled}>
                  <Link to={subEl.path} onClick={() => handleSetSelected(subEl.path, menuEl.disabled)}>
                    {subEl.name}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          );
        }
        return null;
      })}

      <Menu.Item className="logout" key="6" icon={<LogoutOutlined />} onClick={handleLogout}>
        Wyloguj się
      </Menu.Item>
      {/* <Button className="trigger" type="text" onClick={handleCollapse} style={{ color: "rgba(255, 255, 255, 0.65)" }}>
        {collapsed ? <RightOutlined /> : <LeftOutlined />}
      </Button> */}
    </Menu>
  );
};

export default MainNav;
