import { Navigate, Route, Routes } from "react-router-dom";
import { AdminPanel } from "./AdminPanel";

export * from "./AdminPanel";
export const AdminRoutes = () => {
  return (
    <Routes>
      <Route path={"pracownicy"} element={<AdminPanel />} />
      <Route path="*" element={<Navigate to="pracownicy" />} />
    </Routes>
  );
};
