import "./style.less";
import React, { useState } from "react";
import { Card, message, Popover, Radio, RadioChangeEvent, Typography } from "antd";
import { SubHeader } from "../SubHeader";
import { Consent, getUserConsents, setUserConsent } from "../..";
import { useAuth } from "../../../../providers/auth";
import { Link } from "react-router-dom";
import { CheckCircleFilled, CloseCircleFilled, WarningFilled } from "@ant-design/icons";

interface ConsentsCardProps {
  data: Consent;
  confirm?: boolean;
}

export const ConsentsCard: React.FC<ConsentsCardProps> = ({ data, confirm = false }) => {
  const { handleSetConsents } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSetConsent = async (e: RadioChangeEvent) => {
    const tmpVal: any = Number.parseInt(e.target.value);
    try {
      setLoading(true);
      await setUserConsent(data.symbol, tmpVal);

      const res = await getUserConsents();

      handleSetConsents(res);

      setLoading(false);
    } catch (err) {
      message.error("Wystąpił błąd podczas ustawiania zgody!");
      setLoading(false);
    }
  };

  const Icon = data.symbol ? (
    <Popover
      content={
        data.zgoda === 1
          ? `Będziesz otrzymywał ${data.symbol} w wersji elektronicznej`
          : data.zgoda === -1
          ? `Odrzuciłeś zgodę na ${data.symbol} w wersji elektronicznej`
          : `Nie wyraziłeś jeszcze zgody na ${data.symbol} w wersji elektronicznej`
      }
      trigger="hover"
    >
      {data.zgoda === 1 ? (
        <CheckCircleFilled style={{ color: "#389e0d" }} />
      ) : data.zgoda === -1 ? (
        <CloseCircleFilled style={{ color: "#cf1322" }} />
      ) : (
        <WarningFilled style={{ color: "#fa8c16" }} />
      )}
    </Popover>
  ) : null;
  return (
    <Card bordered={false} className="consents-card">
      <SubHeader title={data.nazwa} icon={Icon}>
        <Radio.Group disabled={loading} onChange={handleSetConsent} value={data.zgoda} className="consents-card-radio">
          <Radio value={-1}>Nie</Radio>
          <Radio value={1}>Tak</Radio>
        </Radio.Group>
      </SubHeader>
      <div className="consents-card-body-wrapper">
        <Typography.Text style={{ display: "inline-block" }}>{data.opis}</Typography.Text>
        {confirm ? (
          <Link
            to={`/dokumenty/${data.symbol}`}
            onClick={(e) => (data.zgoda === 1 ? null : e.preventDefault())}
            style={{ color: data.zgoda === 1 ? "#096dd9" : "#ccc" }}
          >
            Sprawdź swoje {data.symbol}
          </Link>
        ) : null}
      </div>
    </Card>
  );
};
