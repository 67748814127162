import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../types";
import useSessionStoragae from "../hooks/useSessionStorage";
import Spinner from "../components/Spinner";
import api from "../lib/withAxios";
import { message } from "antd";
import { queryClient } from "../lib/react-query";
import { Consent, getUserConsents } from "../features/staff";
import { checkIfUserAlreadyAccepted } from "../utils";

interface AuthContextTypes {
  loading: boolean;
  currentUser: User | null;
  setCurrentUser: (data: any) => void;
  getUser: () => any;
  handleLogout: () => void;
  handleUnauthorized: () => void;
  handleAuthLoading: (val: boolean) => void;
  handleGetConsents: () => void;
  handleSetConsents: (data: Consent[]) => void;
  consents: null | Consent[];
  isFirstEntry: boolean;
  setIsFirstEntry: (val: boolean) => void;
  isRendered: boolean;
}

const AuthContext = createContext<AuthContextTypes>(null!);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useSessionStoragae<User | null>("RCMB_USER_AUTH", null);
  const [consents, setConsents] = useState<null | Consent[]>(null);
  const [isFirstEntry, setIsFirstEntry] = useState(true);
  const [isRendered, setIsRendered] = useState(false);
  const handleAuthLoading = (val: boolean) => {
    setLoading(val);
  };
  const handleClearAuth = () => {
    setCurrentUser(null);
    setConsents(null);
    setIsFirstEntry(true);
  };

  const handleGetConsents = async () => {
    setLoading(true);
    try {
      const res = await getUserConsents();
      const isAlreadyEnter = checkIfUserAlreadyAccepted(res);
      if (isAlreadyEnter) setIsFirstEntry(false);

      setConsents(res);
      setLoading(false);
    } catch (err) {
      message.error("Wystąpił błąd podczas pobierania zgód");
      setLoading(false);
      handleClearAuth();
    }
  };
  const handleSetConsents = (data: Consent[]) => {
    setConsents(data);
  };
  const getUser = () => {
    setLoading(true);
    return api
      .get(`/auth`)
      .then((res) => {
        setCurrentUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLogout = async () => {
    try {
      handleClearAuth();
      navigate("/");
      queryClient.clear();
      message.success("Zostałeś wylogowany");
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnauthorized = async () => {
    try {
      handleClearAuth();
      navigate("/");
      queryClient.clear();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!currentUser) return;
    handleGetConsents();
  }, []);

  useEffect(() => {
    setIsRendered(true);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        loading,
        handleAuthLoading,
        currentUser,
        setCurrentUser,
        getUser,
        handleLogout,
        handleUnauthorized,
        handleGetConsents,
        handleSetConsents,
        consents,
        isFirstEntry,
        setIsFirstEntry,
        isRendered,
      }}
    >
      {loading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
