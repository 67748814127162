import { useState } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Typography, message } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { loginWithEmailAndPassword } from "../../api/loginAdmin";
import { useAuth } from "../../../../providers/auth";
import { LoginValues } from "../..";
import { encode as base64_encode } from "base-64";
const SignInSchema = Yup.object().shape({
  username: Yup.string().email("Niepoprawny email").required("Pole obowiązkowe"),
  password: Yup.string().max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
});

interface LoginFormAdminProps {}

const initialValues: LoginValues = {
  username: "",
  password: "",
};

export const LoginFormAdmin: React.FC<LoginFormAdminProps> = () => {
  const { setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: LoginValues, actions: any) => {
    setLoading(true);
    try {
      const user = await loginWithEmailAndPassword({ ...values });
      const token = base64_encode(`${user.username}:${values.password}`);
      user.token = token;

      setCurrentUser(user);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);

      if (!err.response) return message.error("Coś poszło nie tak!");
      else if (err.response.status === 503) return actions.setFieldError("password", "Serwer chwilowo niedostępny");

      if (err.response.data.message === "Bad credentials") {
        return actions.setFieldError("password", "Błędne hasło");
      } else if (err.response.data.message === "Brak uprawnień !") {
        return actions.setFieldError("password", "Brak uprawnień !");
      } else {
        return actions.setFieldError("username", "Nie ma takiego użytkownika");
      }
    }
  };

  return (
    <Formik validationSchema={SignInSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, errors, touched }) => (
        <FormikForm className="form-wrapper">
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            name="username"
            value={values.username}
            onChange={handleChange}
          />
          {errors.username && touched.username ? (
            <Typography.Text type="danger" style={{ fontSize: "12px" }}>
              {errors.username}
            </Typography.Text>
          ) : null}

          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            name="password"
            placeholder="Hasło"
            value={values.password}
            onChange={handleChange}
          />
          {errors.password && touched.password ? (
            <Typography.Text type="danger" style={{ fontSize: "12px" }}>
              {errors.password}
            </Typography.Text>
          ) : null}

          <Form.Item className="form-wrapper-buttons">
            <Button type="primary" htmlType="submit" className="login-form-button" disabled={loading} loading={loading}>
              Zaloguj
            </Button>
          </Form.Item>
        </FormikForm>
      )}
    </Formik>
  );
};
