export const checkIfPeselValid = (pesel: string) => {
  // let year = parseInt(pesel.substring(0, 2), 10);
  // let month = parseInt(pesel.substring(2, 4), 10) - 1;
  // let day = parseInt(pesel.substring(4, 6), 10);

  // if (month >= 80) {
  //   year += 1800;
  //   month = month - 80;
  // } else if (month >= 60) {
  //   year += 2200;
  //   month = month - 60;
  // } else if (month >= 40) {
  //   year += 2100;
  //   month = month - 40;
  // } else if (month >= 20) {
  //   year += 2000;
  //   month = month - 20;
  // } else {
  //   year += 1900;
  // }

  // let brithDate = new Date();
  // brithDate.setFullYear(year, month, day);

  // let weight = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  // let sum = 0;

  // for (var i = 0; i < weight.length; i++) {
  //   sum += parseInt(pesel.substring(i, i + 1), 10) * weight[i];
  // }

  // sum = sum % 10;

  // let controlDigit = parseInt(pesel.substring(10, 11), 10);
  // let isValid = sum === controlDigit;

  // let gender = "k";

  // if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
  //   gender = "m";
  // }
  const reg = /^[0-9]{11}$/;
  if (reg.test(pesel) == false) {
    return false;
  } else {
    const dig = ("" + pesel).split("");
    let kontrola =
      (1 * parseInt(dig[0]) +
        3 * parseInt(dig[1]) +
        7 * parseInt(dig[2]) +
        9 * parseInt(dig[3]) +
        1 * parseInt(dig[4]) +
        3 * parseInt(dig[5]) +
        7 * parseInt(dig[6]) +
        9 * parseInt(dig[7]) +
        1 * parseInt(dig[8]) +
        3 * parseInt(dig[9])) %
      10;
    if (kontrola == 0) kontrola = 10;
    kontrola = 10 - kontrola;
    if (parseInt(dig[10]) == kontrola) return true;
    else return false;
  } // ~ Konrad

  // return {
  //   valid: isValid,
  //   sex: gender,
  //   date: brithDate,
  // };
};
