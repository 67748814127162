import { useMutation } from "react-query";

import api from "../../../lib/withAdminAxios";
import { MutationConfig, queryClient } from "../../../lib/react-query";
// import { useNotificationStore } from '@/stores/notifications';
import { Worker } from "../types";
import { message } from "antd";

export type UpdateWorkerDTO = {
  id: number;
};

export const forceAcceptWorker = ({ id }: UpdateWorkerDTO): Promise<Worker> => {
  return api.post(`pracownicy/zatwierdz/dostep?zgloszenieId=${id}`);
};

type UseUpdateWorkerOptions = {
  config?: MutationConfig<typeof forceAcceptWorker>;
};

export const useAcceptWorker = ({ config }: UseUpdateWorkerOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      message.success("Aktualizacja udana!");
    },
    onSettled: () => {
      queryClient.refetchQueries("workers");
    },

    ...config,
    mutationFn: forceAcceptWorker,
  });
};
