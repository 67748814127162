import { Typography } from "antd";
import { Layout } from "../components";
import { useLocation, Navigate, Link } from "react-router-dom";

interface RegisterSuccessProps {}
export const RegisterSuccess: React.FC<RegisterSuccessProps> = () => {
  const location = useLocation();

  if (!location.state?.fromRegister || !location.state?.userName) {
    return <Navigate to="/" />;
  }
  return (
    <Layout>
      <Typography.Title level={1} style={{ fontSize: "30px", marginBottom: "24px", textAlign: "left" }}>
        Rejestracja pomyślna
      </Typography.Title>
      <Typography.Text>
        Na podany adress email został wysłany link potwierdzający. Kliknij w niego aby potwierdzić swój email.
      </Typography.Text>
      <div style={{ marginTop: "12px", marginBottom: "12px" }}>
        <Link to="/">Strona logowania</Link>
      </div>
    </Layout>
  );
};
