import { Typography } from "antd";
import { LoginAdminLayout } from "../components";

interface LoginAdminProps {}
export const LoginAdmin: React.FC<LoginAdminProps> = () => {
  return (
    <LoginAdminLayout>
      <Typography.Title level={1} style={{ fontSize: "30px", marginBottom: "24px", textAlign: "center" }}>
        Panel logowania dla Admina
      </Typography.Title>
    </LoginAdminLayout>
  );
};
