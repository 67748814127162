import api from "../../../lib/withAxios";
import { useQuery } from "react-query";
import { QueryConfig } from "../../../lib/react-query";

export const getUserCred = async () => {
  return api.get("/pracownik/dane").then((res) => res.data);
};

export const useCredencials = () => {
  return useQuery({
    queryKey: "cred",
    queryFn: () => getUserCred(),
  });
};
