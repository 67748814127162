import "./style.less";
import LogoNormal from "../../images/rcmb.png";
import { Link } from "react-router-dom";
interface LogoProps {
  isSmallVersion?: boolean;
  isLink?: boolean;
  sublogo?: boolean;
}

const Logo: React.FC<LogoProps> = ({ isSmallVersion = false, isLink = true, sublogo = false }) => {
  return (
    <div className="logo">
      {isLink ? (
        <Link to="/">
          <img
            src={LogoNormal}
            alt="Logo Majster"
            className={`logo-image ${isSmallVersion ? "" : "logo-image-large"}`}
          />
        </Link>
      ) : (
        <img src={LogoNormal} alt="Logo Majster" className={`logo-image ${isSmallVersion ? "" : "logo-image-large"}`} />
      )}
    </div>
  );
};

export default Logo;
