import "../style.less";
import { UserDetailsCard } from "../../staff";

interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = () => {
  return (
    <div className="grid-wrapper">
      <UserDetailsCard />
    </div>
  );
};
