import "./style.less";
import { Typography } from "antd";
import React from "react";

interface SubHeaderProps {
  title: string;
  children?: React.ReactNode;

  icon?: React.ReactNode | null;
}

export const SubHeader: React.FC<SubHeaderProps> = ({ title, children, icon }) => {
  return (
    <Typography.Title level={5} className="user-details-subheader">
      <span>
        {icon ? <span style={{ marginRight: "6px" }}>{icon}</span> : null}
        {title}
      </span>
      {children}
    </Typography.Title>
  );
};
