import { pdf } from "@react-pdf/renderer";
import { message } from "antd";
import FileSaver from "file-saver";
import mime from "mime-types";
const generatePdfDocument = async (Pdf: any) => {
  const blob = await pdf(<Pdf />).toBlob();
  return blob;
};

export default generatePdfDocument;

export const saveAsBlob = (doc: any, name: string) => {
  try {
    const type = mime.lookup(name);

    const blob = new Blob([doc], { type: type || "application/pdf" });
    FileSaver.saveAs(blob, name);
  } catch (err) {
    console.log(err);
    message.error("Błąd podczas zapisu pdf!");
  }
};
