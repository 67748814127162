import React from "react";
import "./style.less";
import { Layout, Menu, Breadcrumb, Typography } from "antd";
import AppVersion from "../../../../components/AppVersion";
import { LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../providers/auth";
import Logo from "../../../../components/Logo";
const { Header, Content, Footer } = Layout;

interface AdminLayoutProps {}

export const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  const { handleLogout } = useAuth();
  return (
    <Layout className="layout">
      <Header>
        <Logo />

        <Menu theme="light" mode="horizontal" defaultSelectedKeys={["users"]}>
          <Menu.Item key="6" icon={<LogoutOutlined />} onClick={handleLogout}>
            Wyloguj się
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        <Typography.Text type="secondary" style={{ marginRight: "6px" }}></Typography.Text>
        <AppVersion />
      </Footer>
    </Layout>
  );
};
