import "./style.less";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Highlighter from "react-highlight-words";
import { Table, Tag, Space, Input, Badge, Tooltip, Radio, Typography, RadioChangeEvent } from "antd";
import { Button } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Worker } from "../../types";
import Buttons from "./Buttons";
interface UsersTableProps {
  data: Worker[];
}

const statusOptions = [
  { label: "Wszystkie", value: 2 },
  { label: "Do weryfikacji", value: 0 },
  { label: <Typography.Text type="success">Zaakceptowane</Typography.Text>, value: 1 },
  { label: <Typography.Text type="danger">Odrzucone</Typography.Text>, value: -1 },
];

export const UsersTable: React.FC<UsersTableProps> = ({ data }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRadio, setSelectedRadio] = useState<-1 | 0 | 1 | 2>(2);
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Wyszukaj po ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Szukaj
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Resetuj
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtruj
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        if (!searchInput || !searchInput.current) return;
        //@ts-ignore
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 70,
      sorter: (a: Worker, b: Worker) => (a.id > b.id ? 1 : -1),
    },
    {
      title: "Imię",
      dataIndex: "imie",
      key: "imie",
      width: 95,
      ...getColumnSearchProps("imie"),
      sorter: (a: Worker, b: Worker) => a.imie.length - b.imie.length,
    },
    {
      title: "Nazwisko",
      dataIndex: "nazwisko",
      key: "nazwisko",
      // width: 110,
      ...getColumnSearchProps("nazwisko"),
      sorter: (a: Worker, b: Worker) => a.nazwisko.length - b.nazwisko.length,
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",

      textWrap: "word-break",
      sorter: (a: Worker, b: Worker) => a.email.length - b.email.length,
      ...getColumnSearchProps("email"),
    },
    {
      title: "Pesel",
      dataIndex: "pesel",
      key: "pesel",
      // width: 100,
      // sorter: (a: Worker, b: Worker) => a.pesel.length - b.pesel.length,
      ...getColumnSearchProps("pesel"),
    },
    {
      title: "Telefon",
      dataIndex: "telefon",
      key: "telefon",
      // width: 105,
      ...getColumnSearchProps("telefon"),
    },
    {
      title: "Data wpisu",
      dataIndex: "dataWpisu",
      key: "dataWpisu",
      ellipsis: true,
      // width: 100,
      // ...getColumnSearchProps("dataWpisu"),
      sorter: (a: Worker, b: Worker) => (a.dataWpisu > b.dataWpisu ? -1 : 1),
      render: (text: string) => dayjs(text).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "Weryfikacja",
      children: [
        {
          title: () => (
            <Badge
              count={
                <Tooltip title="Pracownik zweryfikował email i telefon?">
                  <QuestionCircleOutlined />
                </Tooltip>
              }
            >
              <span>Email i Telefon</span>
            </Badge>
          ),
          dataIndex: "sprawdzone",
          key: "sprawdzone",
          // width: 70,
          render: (value: any, record: Worker) => {
            if (value === 1)
              return (
                <div>
                  <Tag icon={<CheckCircleOutlined />} color="success" style={{ marginRight: "4px" }}>
                    Tak
                  </Tag>
                  <p style={{ margin: 0, fontSize: "11px" }}>
                    {dayjs(record.sprawdzoneData).format("DD-MM-YYYY HH:mm")}
                  </p>
                </div>
              );

            return <Tag color="default">Nie</Tag>;
          },
        },
        {
          title: () => (
            <Badge
              count={
                <Tooltip title="Pracownik zweryfikowany przez kadry?">
                  <QuestionCircleOutlined />
                </Tooltip>
              }
            >
              <span>Kadry</span>
            </Badge>
          ),
          dataIndex: "sprawdzoneKadry",
          key: "sprawdzoneKadry",
          // width: 90,
          render: (value: any, record: Worker) => {
            if (value === 1)
              return (
                <div>
                  <Tag icon={<CheckCircleOutlined />} color="success" style={{ marginRight: "4px" }}>
                    Tak
                  </Tag>
                  <p style={{ margin: 0, fontSize: "11px" }}>
                    {dayjs(record.sprawdzoneKadryData).format("DD-MM-YYYY HH:mm")}
                  </p>
                </div>
              );
            else if (value === 2)
              return (
                <div>
                  <Tag icon={<CheckCircleOutlined />} color="#87d068" style={{ marginRight: "4px" }}>
                    Tak, ręcznie
                  </Tag>
                  <p style={{ margin: 0, fontSize: "11px" }}>
                    {dayjs(record.sprawdzoneKadryData).format("DD-MM-YYYY HH:mm")}
                  </p>
                </div>
              );
            else if (value === -1) {
              return (
                <div>
                  <Tag icon={<CloseCircleOutlined />} color="error" style={{ marginRight: "4px" }}>
                    Nie
                  </Tag>
                  <p style={{ margin: 0, fontSize: "11px" }}>
                    {dayjs(record.sprawdzoneKadryData).format("DD-MM-YYYY HH:mm")}
                  </p>
                </div>
              );
            }
            return <Tag color="default">Nie</Tag>;
          },
        },
      ],
    },
    {
      title: "Akcja",
      key: "action",
      // fixed: "right" as "right",
      width: 200,
      render: (text: any, record: Worker) => <Buttons record={record} />,
    },
  ];

  const handleFilterByStatus = () => {
    const tmp = [...data];
    if (selectedRadio === 2) {
      return setFilteredData([...tmp]);
    } else if (selectedRadio === 0) {
      return setFilteredData(
        tmp.filter(
          (el) =>
            el.sprawdzoneKadry !== 1 && el.sprawdzoneKadry !== -1 && el.sprawdzoneKadry !== 2 && el.sprawdzone === 1
        )
      );
    } else if (selectedRadio === -1) {
      return setFilteredData(tmp.filter((el) => el.sprawdzoneKadry === -1));
    }
    setFilteredData(tmp.filter((el) => el.sprawdzoneKadry === 1 || el.sprawdzoneKadry === 2));
  };
  const handleChangeRadio = (e: RadioChangeEvent) => {
    setSelectedRadio(e.target.value);
  };
  useEffect(() => {
    handleFilterByStatus();
  }, [selectedRadio, data]);
  return (
    <div>
      <header style={{ margin: "24px 0", display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography.Text>Kadry status</Typography.Text>
        <Radio.Group options={statusOptions} value={selectedRadio} onChange={handleChangeRadio} />
      </header>
      <Table columns={columns} dataSource={filteredData} scroll={{ x: 1000 }} />
    </div>
  );
};
