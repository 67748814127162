import "./style.less";
import React from "react";
import AppVersion from "../../../../components/AppVersion";
import { LoginFormAdmin } from "./LoginFormAdmin";

interface LoginAdminLayoutProps {}

export const LoginAdminLayout: React.FC<LoginAdminLayoutProps> = ({ children }) => {
  return (
    <div className="admin-layout">
      <div className="admin-layout-content">
        <div className="admin-layout-content-wrapper">
          {children}
          <LoginFormAdmin />
          <div className="admin-app-version">
            <AppVersion />
          </div>
        </div>
      </div>
    </div>
  );
};
