import React from "react";
import { UsersTable } from "../components";
import { useWorkers } from "../api/getWorkers";
import { Typography } from "antd";
import Spinner from "../../../components/Spinner";
interface AdminPanelProps {}

export const AdminPanel: React.FC<AdminPanelProps> = () => {
  const workersQuery = useWorkers({});
  if (workersQuery.isLoading) return <Spinner />;
  if (workersQuery.isError || !workersQuery.data)
    return <Typography.Text type="danger">Wystąpił błąd podczas pobierania danych</Typography.Text>;

  return (
    <>
      <Typography.Title level={2}>Pracownicy</Typography.Title>
      <UsersTable data={workersQuery.data} />
    </>
  );
};
