import { useMutation } from "react-query";
import { MutationConfig, queryClient } from "../../../lib/react-query";
import { Worker } from "../types";
import { message } from "antd";
import api from "../../../lib/withAdminAxios";

export type DeleteWorkerDTO = {
  id: number;
};

export const deleteWorker = ({ id }: DeleteWorkerDTO): Promise<Worker> => {
  return api.delete(`/zgloszenia/${id}`);
};

type UseDeleteWorkerOptions = {
  config?: MutationConfig<typeof deleteWorker>;
};

export const useDeleteWorker = ({ config }: UseDeleteWorkerOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      message.success("Zgłoszenie usunięte!");
    },
    onSettled: () => {
      queryClient.refetchQueries("workers");
    },

    ...config,
    mutationFn: deleteWorker,
  });
};
