import { Button, Popconfirm, Space } from "antd";
import { useDeleteWorker } from "../../api/deleteWorker";
import { useAcceptWorker } from "../../api/forceAccept";
import { useUpdateWorker } from "../../api/updateWorker";
import { Worker } from "../../types";
interface ButtonsProps {
  record: Worker;
}

const Buttons: React.FC<ButtonsProps> = ({ record }) => {
  const updateWorker = useUpdateWorker();
  const acceptWorker = useAcceptWorker();
  const deleteWorker = useDeleteWorker();

  const handleActivate = (id: number) => {
    try {
      updateWorker.mutate({ id });
    } catch (err) {
      console.log(err);
    }
  };
  const handleForceAccept = (id: number) => {
    try {
      acceptWorker.mutate({ id });
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = (id: number) => {
    try {
      deleteWorker.mutate({ id });
    } catch (err) {
      console.log(err);
    }
  };

  if (record.sprawdzoneKadry === 1 || record.sprawdzoneKadry === 2) return null;
  else {
    return (
      <Space size="middle">
        {record.sprawdzone === 1 && record.sprawdzoneKadry === -1 ? (
          <Button
            type={record.sprawdzone ? "primary" : "ghost"}
            onClick={() => handleForceAccept(record.id)}
            disabled={acceptWorker.isLoading}
          >
            Zatwierdź
          </Button>
        ) : null}

        {record.sprawdzone === 1 && record.sprawdzoneKadry !== -1 ? (
          <Button
            type={record.sprawdzone ? "primary" : "ghost"}
            onClick={() => handleActivate(record.id)}
            disabled={updateWorker.isLoading}
          >
            Zweryfikuj
          </Button>
        ) : null}
        <Popconfirm
          placement="topLeft"
          title={"Jesteś pewny/na?"}
          onConfirm={() => handleDelete(record.id)}
          okText="Tak"
          cancelText="Anuluj"
        >
          <Button type={"ghost"} danger disabled={deleteWorker.isLoading}>
            Usuń
          </Button>
        </Popconfirm>
      </Space>
    );
  }
};

export default Buttons;
