import Header from "../../../components/Header";
import { EditForm, FullPageWrapper } from "../components";

interface UserDetailsProps {}

export const UserDetails: React.FC<UserDetailsProps> = () => {
  return (
    <FullPageWrapper>
      <Header title="Edytuj dane osobowe" />
      <EditForm />
    </FullPageWrapper>
  );
};
