import "./style.less";
interface FullPageWrapperProps {
  children: React.ReactNode;
  center?: boolean;
}

export const FullPageWrapper: React.FC<FullPageWrapperProps> = ({ children, center }) => {
  return (
    <div className="staff-details" style={{ margin: center ? "0 auto" : "auto" }}>
      {children}
    </div>
  );
};
