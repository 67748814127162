import { useQuery } from "react-query";
import { QueryConfig } from "../../../lib/react-query";
import { Worker } from "../types";
import api from "../../../lib/withAdminAxios";

export const getWorkers = (): Promise<Worker[]> => {
  return api.get(`/zgloszenia/lista`).then((res) => res.data);
};

type UsePWorkersOptions = {
  config?: QueryConfig<typeof getWorkers>;
};

export const useWorkers = ({}: UsePWorkersOptions) => {
  return useQuery({
    queryKey: "workers",
    queryFn: () => getWorkers(),
  });
};
