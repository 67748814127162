import "./style.less";
import SpecificInformation from "./SpecificInformation";
import GeneralInformation from "./GeneralInformation";
import ChangePassword from "./ChangePassword";
import Spinner from "../../../../components/Spinner";
import { useCredencials } from "../..";
import { Typography } from "antd";

interface EditFormProps {}

export const EditForm: React.FC<EditFormProps> = () => {
  const credQuery = useCredencials();

  return credQuery.isLoading ? (
    <Spinner />
  ) : credQuery.isError ? (
    <Typography.Text type="danger">Coś poszło nie tak. Odśwież i spróbuj ponownie!</Typography.Text>
  ) : (
    <>
      <GeneralInformation user={credQuery.data} />
      <SpecificInformation user={credQuery.data} />
      <ChangePassword />
    </>
  );
};
