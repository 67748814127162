import { Typography } from "antd";
import Header from "../../../components/Header";
import { useAuth } from "../../../providers/auth";
import { FullPageWrapper, ConsentsCard } from "../components";

interface ConsentsProps {}

export const Consents: React.FC<ConsentsProps> = () => {
  const { consents } = useAuth();

  return (
    <FullPageWrapper>
      <Header title="Zgody na dokumenty elektroniczne" style={{ marginBottom: 0 }} />
      <Typography.Text>
        Możesz zarządzać określonymi zgodami, które wykorzystujemy w celu dostarczenia Ci dokumentów w wersji
        elektronicznej. Zapamiętamy Twoje ustawienia i zastosujemy je za każdym razem, gdy zalogujesz się na
        Epracowniku.
      </Typography.Text>
      <Typography.Text>Możesz przejrzeć albo zmienić wybrane opcje w dowolnym momencie.</Typography.Text>
      {consents?.map((consent) => (
        <ConsentsCard data={consent} key={consent.id} confirm />
      ))}
    </FullPageWrapper>
  );
};
