import React from "react";
import { useRoutes } from "react-router-dom";
import { NotFound } from "../features/common";
import { useAuth } from "../providers/auth";
import { protectedRoutes, protectedAdminRoutes } from "./protected";
import { publicRoutes } from "./public";
import { checkAdminAuthority } from "../utils/auth";
import { lazyImport } from "../utils/lazyImport";

const { ConsentsFullPage } = lazyImport(() => import("../features/staff"), "ConsentsFullPage");
export const AppRoutes = () => {
  const { currentUser, isFirstEntry } = useAuth();

  const commonRoutes = [{ path: "*", element: <NotFound /> }];

  const routes = currentUser
    ? checkAdminAuthority(currentUser.authorities)
      ? [...protectedAdminRoutes, ...protectedRoutes]
      : !isFirstEntry
      ? protectedRoutes
      : [{ path: "/", element: <ConsentsFullPage /> }]
    : publicRoutes;
  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
