import "./style.less";
import React, { useEffect, useState } from "react";
import { MenuOutlined, CloseOutlined, LogoutOutlined } from "@ant-design/icons";
import { Layout, Grid, Button, Typography, Popover } from "antd";
import Logo from "../Logo";
import MainNav from "./MainNav";
import AppFooter from "../AppFooter";
import { useAuth } from "../../providers/auth";
const { useBreakpoint } = Grid;
const { Content, Sider } = Layout;

interface MainLayoutProps {
  children: React.ReactNode;
  grid?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, grid = false }) => {
  const { currentUser, handleLogout } = useAuth();
  const breakpoints = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (breakpoints.md) {
      setCollapsed(false);
    }
  }, [breakpoints]);
  return (
    <Layout className="main-layout">
      <Sider
        trigger={null}
        collapsible
        collapsedWidth={!breakpoints.md ? 0 : 60}
        width={170}
        collapsed={collapsed}
        theme="light"
        className={`${!breakpoints.md ? "ant-layout-sider-mobile" : ""} ${
          !breakpoints.md && !collapsed ? "ant-layout-sider-mobile-show" : ""
        }`}
      >
        {!breakpoints.md ? null : (
          <>
            <Logo sublogo />
          </>
        )}
        <MainNav handleCollapse={handleCollapse} />
      </Sider>
      {!breakpoints.md ? (
        <header className="mobile-header">
          <Button
            type="text"
            size="small"
            onClick={handleCollapse}
            className="mobile-nav-trigger"
            icon={collapsed ? <MenuOutlined /> : <CloseOutlined />}
          />
          <div className="mobile-header-logo">
            <Logo />
          </div>
        </header>
      ) : null}
      <Layout className="site-layout">
        {breakpoints.md ? (
          <header className="site-layout-desktop-header">
            <div>
              <Typography.Text style={{ marginRight: "8px" }}>{currentUser?.email}</Typography.Text>
              <Popover placement="topLeft" content="Wyloguj się" trigger="hover">
                <Button icon={<LogoutOutlined />} danger onClick={handleLogout} size="small" />
              </Popover>
            </div>
          </header>
        ) : null}
        <Content
          className={`site-layout-background-content ${collapsed ? "site-layout-background-content-collapsed" : ""} ${
            grid ? "site-layout-background-content-grid" : ""
          }`}
        >
          {children}
        </Content>
        <AppFooter />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
