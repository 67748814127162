import * as Yup from "yup";
import { Form, Input, Button, Typography, Card, message, Badge, Tooltip } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { SubHeader } from "../SubHeader";
import { ChangePass } from "../../types";
import { FileProtectOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { changePassword } from "../..";
import { useAuth } from "../../../../providers/auth";
interface ChangePasswordProps {}
const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Pole obowiązkowe"),
  newPassword: Yup.string()
    .max(20, "Maximum 20 znaków!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      "Hasło musi zawierać minimum 8 znaków, conajmniej jedną dużą, małą literę, jedną cyfrę oraz jeden znak specjalny (!@#$%^&*()_+)"
    )
    .required("Pole obowiązkowe!"),
  repeatPass: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Hasła nie są takie same!")
    .required("Pole obowiązkowe!"),
});

const initialValues: ChangePass = {
  oldPassword: "",
  newPassword: "",
  repeatPass: "",
};

const ChangePassword: React.FC<ChangePasswordProps> = () => {
  const { handleLogout } = useAuth();
  const handleSubmit = async (values: ChangePass, actions: any) => {
    const { repeatPass, ...rest } = values;
    try {
      await changePassword(rest);
      handleLogout();
      message.success("Zmiana hasła udana... Zaloguj sie ponownie!");
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        actions.setFieldError("oldPassword", err.response.data.message);
      }
    }
  };

  return (
    <Card bordered={false}>
      <Formik validationSchema={ChangePasswordSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleChange, errors, touched }) => (
          <FormikForm className="user-edit-form">
            <SubHeader title="Zmień hasło" icon={<FileProtectOutlined />} />

            <Form.Item label="Obecne hasło">
              <Input.Password
                type="password"
                name="oldPassword"
                placeholder="Podaj obecne hasło"
                value={values.oldPassword}
                onChange={handleChange}
                autoComplete="current-password"
              />
              {errors.oldPassword && touched.oldPassword ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.oldPassword}
                </Typography.Text>
              ) : null}
            </Form.Item>
            <Form.Item
              label={
                <Badge
                  offset={[15, 0]}
                  count={
                    <Tooltip title="Hasło musi zawierać minimum 8 znaków, conajmniej jedną dużą, małą literę, jedną cyfrę oraz jeden znak specjalny (!@#$%^&*()_+)">
                      <QuestionCircleFilled />
                    </Tooltip>
                  }
                >
                  <span>Nowe hasło</span>
                </Badge>
              }
            >
              <Input.Password
                type="password"
                name="newPassword"
                placeholder="Podaj nowe hasło"
                value={values.newPassword}
                onChange={handleChange}
                autoComplete="new-password"
              />
              {errors.newPassword && touched.newPassword ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.newPassword}
                </Typography.Text>
              ) : null}
            </Form.Item>
            <Form.Item label="Powtórz hasło">
              <Input.Password
                type="password"
                name="repeatPass"
                placeholder="Powtórz hasło"
                value={values.repeatPass}
                onChange={handleChange}
                autoComplete="new-password"
              />
              {errors.repeatPass && touched.repeatPass ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.repeatPass}
                </Typography.Text>
              ) : null}
            </Form.Item>
            <Button type="primary" style={{ marginTop: "12px" }} htmlType="submit">
              Zapisz
            </Button>
          </FormikForm>
        )}
      </Formik>
    </Card>
  );
};

export default ChangePassword;
