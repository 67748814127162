import "./style.less";
import Logo from "../../../images/rcmb.png";
import { Link } from "react-router-dom";
import AppVersion from "../../../components/AppVersion";

interface LayoutProps {
  children: React.ReactNode;
  contentFullWidth?: boolean;
  large?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({ children, contentFullWidth = false, large = false }) => {
  return (
    <div className="auth-layout">
      <div className="auth-layout-banner">
        <div className="auth-layout-banner-inner">
          <Link to="/">
            <img src={Logo} alt="logo majster" className="auth-layout-banner-inner-logo" />
          </Link>
        </div>
      </div>
      <div className="auth-layout-content">
        <div
          className="auth-layout-content-wrapper"
          style={contentFullWidth ? { maxWidth: "100%" } : large ? { maxWidth: "700px" } : {}}
        >
          {children}
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <AppVersion />
      </div>
    </div>
  );
};
