import { Typography } from "antd";
import preval from "preval.macro";

interface AppVersionProps {}

const AppVersion: React.FC<AppVersionProps> = () => {
  const dateTime = preval`module.exports = new Date().toLocaleString();`;
  return (
    <Typography.Text type="secondary">
      v{process.env.REACT_APP_VERSION}{" "}
      {/* {moment(preval`module.exports = new Date().toLocaleString();`).format("DD MM YYYY")}" " */}
      {dateTime.split(",")[0]}
    </Typography.Text>
  );
};

export default AppVersion;
