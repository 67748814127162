import { useMutation } from "react-query";
import { MutationConfig, queryClient } from "../../../lib/react-query";
import { Worker } from "../types";
import { message } from "antd";
import api from "../../../lib/withAdminAxios";

export type UpdateWorkerDTO = {
  id: number;
};

export const updateWorker = ({ id }: UpdateWorkerDTO): Promise<Worker> => {
  return api.post(`/pracownicy/aktywuj/dostep?zgloszenieId=${id}`);
};

type UseUpdateWorkerOptions = {
  config?: MutationConfig<typeof updateWorker>;
};

export const useUpdateWorker = ({ config }: UseUpdateWorkerOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      message.success("Aktualizacja udana!");
    },
    onSettled: () => {
      queryClient.refetchQueries("workers");
    },

    ...config,
    mutationFn: updateWorker,
  });
};
